<template>
  <b-modal
    id="modal-filter-col-tbl"
    ok-only
    ok-title="Filtra"
    centered
    size="lg"
    title="Filtra"
    @ok="handleOk"
  >
    <b-form-group label="Personalizza la tabella solo con le colonne che preferisci">
      <b-form-checkbox
        v-for="option in colOption"
        :key="option.value"
        v-model="selected"
        :value="option.value"
        name="flavour-3a"
      >
        {{ option.text }}
      </b-form-checkbox>
    </b-form-group>
    <b-card-text>selezionati: {{ selected }}</b-card-text>
    <b-card-text>passati dalla table: {{ colOption }}</b-card-text>
  </b-modal>
</template>

<script>
import {
  BCardText, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCardText, BFormGroup, BFormCheckbox,
  },
  props: {
    columns: { type: Array, required: true },
  },
  data() {
    return {
      selected: [],
      colOption: [],
    }
  },
  beforeMount() {
    this.colOption = this.columns.map(col => ({ text: col.label, value: col.field }))
    this.selected = this.colOption.map(col => col.value)
    // console.log('colOption', this.colOption)
  },
  methods: {
    handleOk() {
      this.$emit('filterTable', this.selected)
    },
  },
}
</script>

<style>

</style>
